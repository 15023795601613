<template>
  <div>
    <template v-if="!isShowDetail" v-loading="loading">
      <el-row type="flex" justify="space-between">
        <div class="left-item">
          <el-row>
            <!-- 转运调度执行机构要 -->
            <el-select
              v-model="params.orgCode1"
              placeholder="转运调度执行机构"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in orgList"
                :key="item.orgCode"
                :label="item.orgName"
                :value="item.orgCode"
              />
            </el-select>
            <!-- 任务编号 -->
            <el-input
              v-model="params.renwuNumber"
              placeholder="任务编号"
              class="query-width"
              size="medium"
              clearable
            />
            <!-- 居民姓名、联系电话、证件号码 -->
            <el-input
              style="width:250px;"
              v-model="params.mainMessage"
              placeholder="居民姓名、联系电话、证件号码"
              class="query-width"
              size="medium"
              clearable
            />
            <!-- 人员信息推送时间 -->
            <el-date-picker style="width: 300px;" v-model="time" size="medium" type="daterange" format="yyyy-MM-dd" @change="handelRiqi" clearable unlink-panels
              range-separator="至" start-placeholder="推送开始日期" end-placeholder="推送结束日期" value-format="yyyy-MM-dd" />
            <!-- 人员转运状态 -->
            <el-select
              v-model="params.peopleStatue"
              placeholder="人员转运状态"
              class="query-width"
              size="medium"
              clearable
              filterable
              style="margin-left:20px;"
            >
              <el-option
                v-for="item in peopleStatueList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <!-- 人群分类 -->
            <el-select
              style="width:280px;"
              multiple
              v-model="params.crowdTypeIds"
              placeholder="人群分类"
              class="query-width"
              size="medium"
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="item in typepopSelect"
                :key="item.id"
                :label="item.ruleName"
                :value="item.id"
              />
            </el-select>
            <!-- 社区负责机构 -->
            <el-select
              v-model="params.orgCode2"
              placeholder="社区负责机构"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in orgList2"
                :key="item.orgCode"
                :label="item.orgName"
                :value="item.orgCode"
              />
            </el-select>
            <!-- 事件名称 -->
            <el-select
              v-model="params.eventName"
              placeholder="事件名称"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in eventNameList"
                :key="item.id"
                :label="item.eventName"
                :value="item.id"
              />
            </el-select>
            <!-- 送达隔离点 -->
            <el-select
              v-model="params.geliNode"
              placeholder="送达隔离点"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in geliNodeList"
                :key="item.id"
                :label="item.orgName"
                :value="item.id"
              />
            </el-select>
            <!-- 转运司机负责人 -->
            <el-select
              v-model="params.diverPeople"
              placeholder="转运司机负责人"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in diverPeopleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <!-- 转运社区负责人 -->
            <el-select
              v-model="params.communityPeople"
              placeholder="转运社区负责人"
              class="query-width"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in communityPeopleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <!-- 任务来源 -->
            <el-select
              v-model="params.renwuLaiyuan"
              placeholder="任务来源"
              class="query-width"
              size="medium"
              clearable
            >
              <el-option
                v-for="item in renwuLaiyuanList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <!-- 创建任务时间 -->
            <!-- <el-date-picker type="date" v-model="reportTime"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="创建任务时间">
            </el-date-picker> -->
            <el-date-picker style="width: 400px;" v-model="reportTime" size="medium" type="daterange" format="yyyy-MM-dd" @change="handelRiqi2" clearable unlink-panels
              range-separator="至" start-placeholder="创建任务开始日期" end-placeholder="创建任务结束日期" value-format="yyyy-MM-dd" />
            <!-- 送达隔离点时间 -->
            <!-- <el-date-picker style="margin-left:20px;" type="date" v-model="arriveTime"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="送达隔离酒店时间">
            </el-date-picker> -->
            <el-date-picker style="width: 400px;margin-left:20px;" v-model="arriveTime" size="medium" type="daterange" format="yyyy-MM-dd" @change="handelRiqi3" clearable unlink-panels
              range-separator="至" start-placeholder="送达隔离酒店开始日期" end-placeholder="送达隔离酒店结束日期"  value-format="yyyy-MM-dd" />
            <!-- <el-select
              style="margin-left:20px;"
              v-model="params.taskStatus"
              placeholder="任务状态"
              class="query-width"
              size="medium"
              clearable
              filterable
              multiple

            >
              <el-option
                v-for="item in taskStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
            <el-cascader
              placeholder="任务状态"
              style="margin-left:20px;width: 300px;"
              v-model="params.taskStatus"
              :options="taskStatusList"
              :props="props"
              collapse-tags
              clearable
              filterable>
            </el-cascader>
          </el-row>
        </div>

        <div class="right-item">
          <el-button type="primary" size="medium" @click="queryData('btn')" :disabled="loading"
            >查询</el-button
          >
          <el-button type="primary" size="medium" @click="escalateExport('btn')"
          v-loading.fullscreen.lock="fullscreenLoading" :disabled="loading" style="margin: 20px 0 0 0;">导出</el-button
          >
        </div>
      </el-row>

      <div class="table-data">
        <TableModel
          :is-show-select="true"
          :loading="loading"
          :table-data="tableList"
          :goBeyondHeight="40"
          table-size="medium"
          :checkData="checkData"
          @handleSelectionChange="handleSelection"
        >
          <el-table-column prop="transportStatus" type="selection" width="55" :selectable="handleDisable">

          </el-table-column>
          <el-table-column prop="transportStatus" fixed="left" label="操作" width="140">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row)"
                >查看详情</el-button
              >
              <el-button
                v-if="scope.row.transportStatus == '1'"
                type="text"
                size="small"
                @click="resetTransportDan(scope.row)"
                v-btn-permission="'008009002001'"
                >重新转运</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="taskNo" label="任务编号" width="150" />
          <el-table-column prop="transportStatusName" label="人员转运状态" width="150">
            <!-- <template slot-scope="scope">{{
              scope.row.cardType | dictFilter2(cardTypeDict)
            }}</template> -->
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="80" />
          <el-table-column prop="cardTypeName" label="证件类型" width="100" />
          <el-table-column prop="cardNo" label="证件号码" width="200" />
          <el-table-column prop="linkPhone" label="联系电话" width="150"/>
          <el-table-column prop="crowdTypeName" label="人群分类" width="200"/>
          <el-table-column prop="currentAddressDetail" label="当前居住地址"
            show-overflow-tooltip width="200"/>
          <el-table-column prop="regTypeName" label="任务来源" show-overflow-tooltip width="100"/>
          <el-table-column prop="treatUserPhone" label="推送人员" width="200"/>
          <el-table-column prop="crtTime" label="推送日期" width="200"/>
          <el-table-column prop="taskCrtTime" label="任务创建时间" width="200"/>
          <el-table-column prop="taskUserPhone" label="任务创建人" width="200"
          />
          <el-table-column prop="taskOrgName" label="转运调度机构" width="200">
          </el-table-column>
          <el-table-column prop="receiveAddressDetail" label="居民上车地点" width="200">
          </el-table-column>
          <el-table-column prop="isolationNamePhone" label="送往隔离点" width="200">
          </el-table-column>
          <el-table-column prop="giveIsolationTime" label="送往隔离点时间" width="200">
          </el-table-column>
          <el-table-column prop="driverUserPhone" label="转运司机" width="200">
          </el-table-column>
          <el-table-column prop="responsibleOrgName" label="社区负责机构" width="120">
          </el-table-column>
          <el-table-column prop="leaderUserPhone" label="社区负责人" width="200">
          </el-table-column>
          <el-table-column prop="refuseRemark" label="原因" width="200">
          </el-table-column>
          <el-table-column prop="relationName" label="阳性病关联人员" width="200">
          </el-table-column>
        </TableModel>

        <div class="content_page">
          <div>
            <span>已选中项目&nbsp;&nbsp;&nbsp;{{checkData.length}}&nbsp;&nbsp;&nbsp;批量操作</span>
            <el-button :disabled="loading" style="margin-left:20px;" type="primary" size="medium" @click="resetTransport" v-btn-permission="'008009002001'"
            >重新转运</el-button>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :total="total"
            :page-sizes="[20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </template>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { Loading } from "element-ui";
import TableModel from "@/components/TableModel";
import { getBusinessOrgList,getUserListByPeopleTypeApi,getAllEventList,getAllIsolationOrgList } from "@/api/SystemManagement/index";
import { getAllRuleCrowdList } from "@/api/RuleManagement/crowdAnalysis"
import { getTransportRegLitPage,againTransportReg,exportTransportRegList } from "@/api/Taskmanagement/upcomingTasks"
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import { importFile, messageBox } from "../../utils/utils";
export default {
  data() {
    return {
      reportTime:'',
      arriveTime:'',
      props: { multiple: true },
      params: {
        taskStatus:[],
        orgCode1: "",
        orgCode2: "",
        peopleStatue: "",
        renwuLaiyuan: "",
        mainMessage: "",
        renwuNumber: "",
        eventName:'',
        geliNode:'',
        diverPeople:'',
        crowdTypeIds:[],
        communityPeople:''
      },
      fullscreenLoading: false, //导出的状态
      peopleStatueList: [
        {
          value: "2",
          label: "转运中",
        },
        {
          value: "1",
          label: "取消转运",
        },
        {
          value: "0",
          label: "待转运",
        },
        {
          value: "4",
          label: "完成转运",
        },
      ],
      renwuLaiyuanList: [
        {
          value: "1",
          label: "疾控流调",
        },
        {
          value: "2",
          label: "街镇排查",
        },
      ],
      diverPeopleList:[],
      communityPeopleList:[],
      eventNameList:[],
      tableList: [],
      typepopSelect: [],
      total: 0,
      loading: false,
      checkData: [],
      pageNumber: 1,
      pageSize: 100,
      orgList: [],
      orgList2: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      time: ['',''],
      geliNodeList:[],
      isShowDetail:false,
      days: 0, //计算开始日期和结束日期相差的天数；
      loading:false,
      taskStatusList:[
        {
          value:'10',
          label:'社区待接收任务'
        },
        {
          value:'11',
          label:'社区拒绝任务'
        },
        {
          value:'15',
          label:'司机待接收'
        },
        {
          value:'16',
          label:'司机拒绝任务'
        },
        {
          value:'20',
          label:'司机待处理'
        },
        {
          value:'25',
          label:'司机处理中'
        },
        {
          value:'30',
          label:'等待上车'
        },
        {
          value:'35',
          label:'全部送往隔离点'
        },
        {
          value:'36',
          label:'部分送往隔离点'
        },
        {
          value:'40',
          label:'完成任务'
        },
        {
          value:'50',
          label:'取消'
        },
      ]
    };
  },
  created() {
    let time = moment().format("YYYY-MM-DD");
    this.time[0] = `${time}`;
    this.time[1] = `${time}`;
    // console.log(time)  // 2022-07-01 10:00:16
    // 获取人群分类
    this.getCrowdTypeList();
  },
  mounted() {
    this.getBusinessOrgList();
    this.getBusinessOrgList2();
    this.getUserListByPeopleTypeApi1()
    this.getUserListByPeopleTypeApi2() 
    this.getAllIsolationOrgList()
    this.getAllEventList() //获取事件
    this.getTransportRegLitPage() //获取列表数据
  },
  components: {
    TableModel,residentInformation
  },
  computed: {
    ...mapState({
      isolationList: (state) => state.user.isolationList, // 隔离点列表数据
      nationalitySelect: (state) => state.user.nationalitySelect, // 国籍
    }),
  },
  methods: {
    handleDisable(row, index){
      if (row.transportStatus == '1') {
        return true
      } else {
        return false
      }
    },
    // 获取机构 get 请求
    async getBusinessOrgList() {
      //   转运调度任务执行机构
      let params = {
        // homeIsolation: 1,
        transshipmentDispatching: 1,
        orgCode: this.userdoctor.orgCode,
      };
      const { data } = await getBusinessOrgList({ params });
      this.orgList = data.data;
      //   this.params.orgCode = data.data[0].orgCode;
      //   let item = data.data[0]
      //   this.getSchedulingPeriodList(item)
      //   this.getByDateRange();
    },
    async getBusinessOrgList2() {
      //   转运调度任务执行机构
      let params = {
        // homeIsolation: 1,
        transshipmentTask: 1,
        orgCode: this.userdoctor.orgCode,
      };
      const { data } = await getBusinessOrgList({ params });
      this.orgList2 = data.data;
      //   this.params.orgCode = data.data[0].orgCode;
      //   let item = data.data[0]
      //   this.getSchedulingPeriodList(item)
      //   this.getByDateRange();
    },
    // 获取隔离点酒店机构列表
    getAllIsolationOrgList(){
        getAllIsolationOrgList().then(res=>{
            // console.log(res.data.data);
            this.geliNodeList = res.data.data
        })
    },
    // 获取司机
    getUserListByPeopleTypeApi1(){
        getUserListByPeopleTypeApi({
            params: {
                orgCode: this.userdoctor.orgCode,
                peopleType: "2",
            },
        }).then(res=>{
            this.diverPeopleList = res.data.data
        })
    },
    // 获取社区人员
    getUserListByPeopleTypeApi2(){
        getUserListByPeopleTypeApi({
            params: {
                orgCode: this.userdoctor.orgCode,
                peopleType: "1",
            },
        }).then(res=>{
            this.communityPeopleList = res.data.data
        })
    },
    // 获取事件
    getAllEventList(){
        getAllEventList().then(res=>{
            this.eventNameList = res.data.data
            // console.log(res);
        })
    },
    handelRiqi(res) {
      // console.log(res,'6666');
    if(res == null){
        // console.log('zhe');
        this.time = ['','']
    }
      // console.log(this.time);
    },
    handelRiqi2(res) {
      // console.log(res,'6666');
    if(res == null){
        // console.log('zhe');
        this.reportTime = ['','']
    }
      // console.log(this.time);
    },
    handelRiqi3(res) {
      // console.log(res,'6666');
    if(res == null){
        // console.log('zhe');
        this.arriveTime = ['','']
    }
      // console.log(this.time);
    },
    // 人群分类(所有)
    async getCrowdTypeList() {
      const { data } = await getAllRuleCrowdList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    // 获取列表数据
    async getTransportRegLitPage(){
        // console.log('6666666',this.params.crowdTypeIds);
        // console.log(this.time,'7777777');
        if(this.time == null){
          this.time = ['','']
        }
        if(this.reportTime == null){
          this.reportTime = ['','']
        }
        if(this.arriveTime == null){
          this.arriveTime = ['','']
        }
        let taskStatusNew = []
        this.params.taskStatus.forEach(data=>{
          taskStatusNew.push(data[0])
        })
        let params = {
            crowdTypeIds:this.params.crowdTypeIds,
            crtStartTime:this.time[0],
            crtEndTime:this.time[1],
            taskCrtStartTime:this.reportTime[0],
            taskCrtEndTime:this.reportTime[1],
            giveIsolationStartTime: this.arriveTime[0],
            giveIsolationEndTime: this.arriveTime[1],
            taskStatuss:taskStatusNew,
            eventId:this.params.eventName,
            isolationPointId:this.params.geliNode,
            namePhoneCardNo:this.params.mainMessage,
            pageNumber:this.pageNumber,
            pageSize:this.pageSize,
            regType:this.params.renwuLaiyuan,
            responsibleOrgCode:this.params.orgCode2,
            responsibleOrgLeaderId:this.params.communityPeople,
            taskNo:this.params.renwuNumber,
            taskOrgCode:this.params.orgCode1,
            transportDriverId:this.params.diverPeople,
            transportStatus:this.params.peopleStatue
        }
        // console.log(params);
        this.loading = true
        let { data } = await getTransportRegLitPage(params)
            console.log(data);
            if(data.code == 200){
              this.loading = false
              this.tableList = data.data.list
              this.total = data.data.totalRow
            }else{
              this.loading = false
            }
            
        
    },
    handleSelection(val) {
      this.checkData = val;
      console.log(this.checkData);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTransportRegLitPage();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTransportRegLitPage();
    },
    // 查询
    queryData(val) {
        // console.log(val);
      this.getTransportRegLitPage();
    },
    // 查看详情
    handleClick(data){
      this.isShowDetail = true;
      //修改为查看流调登记ID
      let rowInfo = {id : ""};
      console.log(data);
      rowInfo.id = data.regId;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(rowInfo);
      });
    },
    // 单个重新转运
    resetTransportDan(){
      if(this.checkData.length == 0){
        this.$message({
                type: 'warning',
                message: '此操作至少勾选1条数据!'
        });
        return
      }
      if(this.checkData.length != 1){
        this.$message({
          message: '此处为单个操作，如需批量操作请点击批量操作按钮！',
          type: 'warning'
        });
      }else{
        this.$confirm('确定要将勾选人员状态回到待转运吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let that = this
          this.loading = true
          console.log(this.loading);
          let params = {
            ids:this.checkData
          }
          againTransportReg(params).then(res=>{
            // console.log(res,'7777777777777');
            if(res.data.code == 200){
              this.checkData = []
              this.$message({
                type: 'success',
                message: '转运成功!'
              });
            }else{
              this.$message.error('转运失败！');
            }
            that.queryData()
            this.loading = false
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
      }
    },
    // 批量重新转运
    resetTransport(){
      console.log(this.checkData);
      if(this.checkData.length == 0){
        this.$message({
                type: 'warning',
                message: '批量操作至少操作1条!'
        });
        return
      }
      this.$confirm('确定要将勾选人员状态回到待转运吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let that = this
          this.loading = true
          console.log(this.loading);
          let params = {
            ids:this.checkData
          }
          againTransportReg(params).then(res=>{
            if(res.data.code == 200){
              
              this.checkData = []
              this.$message({
                type: 'success',
                message: '转运成功!'
              });
            }else{
              this.$message.error('转运失败！');
            }
            that.queryData()
            this.loading = false
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },
    // 导出
    escalateExport() {
      if(this.time == null){
          this.time = ['','']
      }
      if(this.reportTime == null){
          this.reportTime = ['','']
      }
      if(this.arriveTime == null){
          this.arriveTime = ['','']
      }
      let taskStatusNew = []
      this.params.taskStatus.forEach(data=>{
          taskStatusNew.push(data[0])
      })
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        crowdTypeIds:this.params.crowdTypeIds,
        crtStartTime:this.time[0],
        crtEndTime:this.time[1],
        crtEndTime:this.time[1],
        taskCrtStartTime:this.reportTime[0],
        taskCrtEndTime:this.reportTime[1],
        giveIsolationStartTime: this.arriveTime[0],
        giveIsolationEndTime: this.arriveTime[1],
        taskStatuss:taskStatusNew,
        eventId:this.params.eventName,
        isolationPointId:this.params.geliNode,
        namePhoneCardNo:this.params.mainMessage,
        pageNumber:this.pageNumber,
        pageSize:this.pageSize,
        regType:this.params.renwuLaiyuan,
        responsibleOrgCode:this.params.orgCode2,
        responsibleOrgLeaderId:this.params.communityPeople,
        taskNo:this.params.renwuNumber,
        taskOrgCode:this.params.orgCode1,
        transportDriverId:this.params.diverPeople,
        transportStatus:this.params.peopleStatue
      };
      // console.log(this.time);
      if (this.time[0] != '' && this.time[1] != '') {
        // console.log('导出');
        params.crtStartTime = this.time[0];
        params.crtEndTime = this.time[1];
        this.days = moment(params.crtEndTime).diff(
          moment(params.crtStartTime),
          "days"
        );
        this.days++; //计算的相差时间少了一天，所以这里加1天 ,做判断用
        // console.log(this.days)
      } else {
        this.days = 0;
      }
      // console.log(params, "获取的导出参数！！！！");

      if (this.days > 90) {
        this.$message({
          type: "error",
          message: "导出数据选择范围不能超过三个月",
        });
        return;
      }
      console.log(this.time, "this.pickerTimethis.pickerTime");
      if (this.time[0] == '' || this.time[1] == '') {
        // console.log("不能导出");
        this.$message({
          type: "error",
          message: "请选择查询条件中的时间范围!",
        });
        return;
      }
      
      // console.log(params, "数据外的的params");
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportTransportRegList(params).then((res) => {
            console.log(res.data);
            importFile(res.data, "转运人员列表导出");
            this.fullscreenLoading = false;
          });
        },
        "warning",
        "确定导出表格中的数据吗？"
      );
      // exportTransportRegList()
    },
    async getTableList(val) {
      // if (val == "btn") {
      //     this.pageNumber = 1;
      // }
      // this.loading = true;
      // if (
      //     this.eventStatus == true
      // ) {
      //     this.queryForm.eventStatus = 1
      // } else {
      //     this.queryForm.eventStatus = 0
      // }
      // // let params = this.setParamsInfo();
      // let eventIds = [],
      //     placeIds = "",
      //     checkds = [],
      //     eventPlaceIds = []
      // let arrrrrrrr = this.queryForm.eventsite;
      // for (let i in arrrrrrrr) {
      //     // arrrrrrrr[i]
      //     if (arrrrrrrr[i][1]) {
      //     eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
      //     eventPlaceIds.push(eventIds)
      //     checkds.push(arrrrrrrr[i][0]);
      //     } else if (arrrrrrrr[i][0] != null) {
      //     eventPlaceIds.push(arrrrrrrr[i][0])
      //     }
      // }
      // let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉
      // let params = {
      //     hospitalId: this.userdoctor.hospitalId,
      //     orgCode: this.userdoctor.orgCode,
      //     pageNumber: this.pageNumber,
      //     pageSize: this.pageSize,
      //     eventPlaceIds: newArr,
      //     searchValue: this.queryForm.searchValue,
      //     country: this.queryForm.country,
      //     crowdTypeIds: this.queryForm.crowdTypeIds,
      //     personStatus: this.queryForm.personStatus,
      //     surveyResults: this.queryForm.surveyResults,
      //     eventStatus: this.queryForm.eventStatus,
      //     surveyUserId: this.surveyUserName,
      // };
      // if (this.time && this.time.length == 2) {
      //     params.startTime = this.time[0] + " 00:00:00";
      //     params.endTime = this.time[1] + " 23:59:59";
      // }
      // const result = await getEpidemiologicalSurveyListApi(params);
      // let { code, data, msg } = result.data;
      // if (code == 200) {
      //     let { totalRow, list } = data;
      //     this.tableList = list;
      //     this.total = totalRow;
      // } else {
      //     this.$message.error(msg);
      // }
      // setTimeout(() => {
      //     this.loading = false;
      // }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/static/css/condition-filtrate.scss";

.query-width {
  width: 220px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.table-data {
  margin-top: 20px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.right-item {
  width: 70px;
  height: 100px;
  // background: red;
}
</style>